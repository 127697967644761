import { ModelObjectType } from '@icepanel/platform-api-client'

export const objectDescriptions: Record<ModelObjectType, string> = {
  actor: 'Person that gets value from your system(s)',
  app: 'Deployable and/or runnable service or client',
  component: 'Building blocks of an app/store',
  group: 'Visual grouping or separation of objects',
  root: 'High-level business verticals',
  store: 'Database or storage bucket',
  system: 'Group of apps/stores, often owned by a team'
}

export const objectIcons: Record<ModelObjectType, string> = {
  actor: '$fas-user',
  app: '$fas-th-large',
  component: '$fas-th',
  group: '$fas-vector-square',
  root: '',
  store: '$fas-database',
  system: '$fas-square'
}
