
import { ModelConnection, ModelObject, ModelObjectPartial, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { ModelModule } from '../../store'

@Component({
  name: 'ModelTechnologyListItemActions'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly model!: ModelObject | ModelConnection
  @Prop() readonly technologyId!: string
  @Prop() readonly expanded!: boolean
  @Prop() readonly hover!: boolean

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get technology () {
    return this.model.technologies[this.technologyId]
  }

  get hasIcon () {
    return 'type' in this.model
  }

  setIcon () {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if ('type' in this.model) {
      const prevObject = window.structuredClone(this.model)

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: this.model.id,
                props: {
                  icon: this.model.icon
                },
                type: 'model-object-update'
              }]
            }
          },
          type: 'diagram-content-update'
        })

        const update: ModelObjectPartial = {
          icon: {
            catalogTechnologyId: this.technology.id,
            name: this.technology.name,
            url: this.technology.iconUrl,
            urlDark: this.technology.iconUrlDark,
            urlLight: this.technology.iconUrlLight
          }
        }

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: this.model.id,
              props: update,
              type: 'model-object-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: this.model.id,
          props: {
            icon: this.model.icon
          },
          type: 'model-object-update'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.model.id, {
          icon: {
            catalogTechnologyId: this.technology.id,
            name: this.technology.name,
            url: this.technology.iconUrl,
            urlDark: this.technology.iconUrlDark,
            urlLight: this.technology.iconUrlLight
          }
        })
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: this.model.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        })

        analytics.modelObjectUpdate.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectDescriptionLength: prevObject.description?.length || 0,
          modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
          modelObjectExternal: prevObject.external,
          modelObjectIconName: prevObject.icon?.name || null,
          modelObjectLinkCount: Object.keys(prevObject.links).length,
          modelObjectNameLength: prevObject.name.length,
          modelObjectParent: prevObject.parentId,
          modelObjectStatus: prevObject.status,
          modelObjectTagCount: prevObject.tagIds.length,
          modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
          modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
          modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
          modelObjectType: prevObject.type,
          modelObjectUpdateIconName: objectUpdate.icon?.name || null,
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })

    this.$emit('close')
  }
}
