
import { ModelConnection, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { iconUrlForTheme } from '@/helpers/theme'

@Component({
  name: 'ModelTechnologyListItem'
})
export default class extends Vue {
  @Prop() readonly model!: ModelObject | ModelConnection
  @Prop() readonly technologyId!: string

  get technology () {
    return this.model.technologies[this.technologyId]
  }

  get iconUrl () {
    return iconUrlForTheme(this.technology)
  }
}
