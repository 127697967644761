
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { ModelConnection, ModelConnectionStatus, ModelObject, ModelObjectStatus, modelStatuses, PermissionType, Task, TaskModelConnectionUpdate, TaskModelObjectUpdate } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import hexToRgb from '@/helpers/hex-to-rgb'
import { iconUrlForTheme } from '@/helpers/theme'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import statusIcons from '../helpers/status-icon'
import { ModelModule } from '../store'

interface Item {
  active: boolean
  activeSome: boolean
  backgroundColor?: string
  click: () => void
  color: string
  icon: string
  id: ModelObjectStatus | ModelConnectionStatus
  subtitle?: string
  title: string
}

@Component({
  name: 'ModelStatus'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelConnection | (ModelObject | ModelConnection)[]
  @Prop() readonly permission!: PermissionType

  iconUrlForTheme = iconUrlForTheme

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get object () {
    return 'type' in this.model && !(this.model instanceof Array) ? this.model : undefined
  }

  get objects () {
    return this.model instanceof Array ? this.model.filter((o): o is ModelObject => 'type' in o) : this.model && 'type' in this.model ? [this.model] : []
  }

  get connection () {
    return !('type' in this.model) && !(this.model instanceof Array) ? this.model : undefined
  }

  get connections () {
    return this.model instanceof Array ? this.model.filter((o): o is ModelConnection => !('type' in o)) : this.model && !('type' in this.model) ? [this.model] : []
  }

  get multiple () {
    return this.items.every(o => !o.active)
  }

  get activeStatuses () {
    return this.items.filter(o => o.activeSome)
  }

  get parentLockingObjectStatus () {
    return this.objects.map(o => o.parentIds.map(o => this.modelModule.objects[o]).find(t => t.status === 'future' || t.status === 'deprecated' || t.status === 'removed'))?.[0]
  }

  get objectLockingConnectionStatus () {
    return this.connections.map(o => {
      const origin = [o.originId, ...this.modelModule.objects[o.originId]?.parentIds || []]
        .map(e => this.modelModule.objects[e]).find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
      const target = [o.targetId, ...this.modelModule.objects[o.targetId]?.parentIds || []]
        .map(e => this.modelModule.objects[e]).find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
      if (origin?.status === 'removed') {
        return origin
      } else if (target?.status === 'removed') {
        return target
      } else if (origin?.status === 'future') {
        return origin
      } else if (target?.status === 'future') {
        return target
      } else if (origin?.status === 'deprecated') {
        return origin
      } else if (target?.status === 'deprecated') {
        return target
      } else {
        return null
      }
    })?.[0]
  }

  get objectLockingStatus () {
    return this.parentLockingObjectStatus || this.objectLockingConnectionStatus
  }

  get statusLocked () {
    return !!this.objectLockingStatus
  }

  get items (): Item[] {
    const purple = hexToRgb(TAG_COLOR_ACTIVE.purple)
    const orange = hexToRgb(TAG_COLOR_ACTIVE.orange)
    const red = hexToRgb(TAG_COLOR_ACTIVE.red)
    return [
      {
        active: this.objects.every(o => o.status === 'live') && this.connections.every(o => o.status === 'live'),
        activeSome: this.objects.some(o => o.status === 'live') || this.connections.some(o => o.status === 'live'),
        click: () => this.updateStatus('live', this.objects, this.connections),
        color: TAG_COLOR_ACTIVE[modelStatuses.live.color],
        icon: statusIcons.live,
        id: 'live',
        subtitle: 'Available currently',
        title: modelStatuses.live.name
      },
      {
        active: this.objects.every(o => o.status === 'future') && this.connections.every(o => o.status === 'future'),
        activeSome: this.objects.some(o => o.status === 'future') || this.connections.some(o => o.status === 'future'),
        backgroundColor: `rgba(${purple?.r}, ${purple?.g}, ${purple?.b}, 0.2)`,
        click: () => this.updateStatus('future', this.objects, this.connections),
        color: TAG_COLOR_ACTIVE[modelStatuses.future.color],
        icon: statusIcons.future,
        id: 'future',
        subtitle: 'Planned implementation',
        title: modelStatuses.future.name
      },
      {
        active: this.objects.every(o => o.status === 'deprecated') && this.connections.every(o => o.status === 'deprecated'),
        activeSome: this.objects.some(o => o.status === 'deprecated') || this.connections.some(o => o.status === 'deprecated'),
        backgroundColor: `rgba(${orange?.r}, ${orange?.g}, ${orange?.b}, 0.2)`,
        click: () => this.updateStatus('deprecated', this.objects, this.connections),
        color: TAG_COLOR_ACTIVE[modelStatuses.deprecated.color],
        icon: statusIcons.deprecated,
        id: 'deprecated',
        subtitle: 'Available but avoid usage as this will be removed',
        title: modelStatuses.deprecated.name
      },
      {
        active: this.objects.every(o => o.status === 'removed') && this.connections.every(o => o.status === 'removed'),
        activeSome: this.objects.some(o => o.status === 'removed') || this.connections.some(o => o.status === 'removed'),
        backgroundColor: `rgba(${red?.r}, ${red?.g}, ${red?.b}, 0.2)`,
        click: () => this.updateStatus('removed', this.objects, this.connections),
        color: TAG_COLOR_ACTIVE[modelStatuses.removed.color],
        icon: statusIcons.removed,
        id: 'removed',
        subtitle: 'Discontinued and no longer used',
        title: modelStatuses.removed.name
      }
    ]
  }

  get currentItem () {
    const objectLockingStatus = this.objectLockingStatus
    return objectLockingStatus ? this.items.find(o => o.id === objectLockingStatus.status) : this.items.find(o => o.active)
  }

  updateStatus (status: ModelObjectStatus, objects: ModelObject[], connections: ModelConnection[]) {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    const childObjectIds = [...new Set(
      this.objects.map(o => [o.id, ...Object.values(this.modelModule.objects).filter(e => e.parentIds.includes(o.id)).map(o => o.id)]).flat()
    )]
    const childConnectionIds = [...new Set([
      ...Object.values(this.modelModule.connections).filter(o => childObjectIds.includes(o.originId) || childObjectIds.includes(o.targetId)).map(o => o.id),
      ...connections.map(o => o.id)
    ])]

    if (this.currentDiagram?.status === 'draft') {
      const statusUpdates = [
        ...objects
          .filter(o => status !== o.status)
          .map((o): TaskModelObjectUpdate => ({
            id: o.id,
            props: {
              status
            },
            type: 'model-object-update'
          })),
        ...connections
          .filter(o => status !== o.status)
          .map((o): TaskModelConnectionUpdate => ({
            id: o.id,
            props: {
              status
            },
            type: 'model-connection-update'
          }))
      ]

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: statusUpdates.map(o => ({
              id: o.id,
              props: {
                status: this.modelModule.objects[o.id]?.status || this.modelModule.connections[o.id]?.status
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: statusUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      objects
        .filter(o => status !== o.status)
        .forEach(o => {
          const prevObject = window.structuredClone(o)

          revertTasks.push({
            id: o.id,
            props: {
              status: o.status
            },
            type: 'model-object-update'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, {
            status
          })
          this.modelModule.setObjectVersion(object)
          this.editorModule.addToTaskQueue({
            func: () => this.modelModule.objectUpdate({
              landscapeId: this.currentLandscape.id,
              objectId: object.id,
              props: objectUpdate,
              versionId: o.versionId
            })
          })

          tasks.push({
            id: object.id,
            props: objectUpdate,
            type: 'model-object-update'
          })

          analytics.modelObjectUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectDescriptionLength: prevObject.description?.length || 0,
            modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
            modelObjectExternal: prevObject.external,
            modelObjectIconName: prevObject.icon?.name || null,
            modelObjectLinkCount: Object.keys(prevObject.links).length,
            modelObjectNameLength: prevObject.name.length,
            modelObjectParent: prevObject.parentId,
            modelObjectStatus: prevObject.status,
            modelObjectTagCount: prevObject.tagIds.length,
            modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
            modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
            modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
            modelObjectType: prevObject.type,
            modelObjectUpdateStatus: objectUpdate.status,
            organizationId: [this.currentLandscape.organizationId]
          })
        })

      connections
        .filter(o => status !== o.status)
        .forEach(o => {
          const prevConnection = window.structuredClone(o)

          revertTasks.push({
            id: o.id,
            props: {
              status: o.status
            },
            type: 'model-connection-update'
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(o.id, {
            status
          })
          this.modelModule.setConnectionVersion(connection)
          this.editorModule.addToTaskQueue({
            func: () => this.modelModule.connectionUpdate({
              connectionId: connection.id,
              landscapeId: this.currentLandscape.id,
              props: connectionUpdate,
              versionId: o.versionId
            })
          })

          tasks.push({
            id: connection.id,
            props: connectionUpdate,
            type: 'model-connection-update'
          })

          analytics.modelConnectionUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelConnectionDescriptionLength: prevConnection.description?.length || 0,
            modelConnectionDirection: prevConnection.direction,
            modelConnectionNameLength: prevConnection.name.length,
            modelConnectionStatus: prevConnection.status,
            modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
            modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
            modelConnectionUpdateStatus: connectionUpdate.status ? connectionUpdate.status : undefined,
            organizationId: [this.currentLandscape.organizationId]
          })
        })
    }

    this.editorModule.addToTaskQueue({ func })

    if (childObjectIds.length && childConnectionIds.length) {
      this.alertModule.pushAlert({
        color: 'grey darken-1',
        message: `Set ${childObjectIds.length} object${childObjectIds.length === 1 ? '' : 's'} and ${childConnectionIds.length} connection${childConnectionIds.length === 1 ? '' : 's'} to ${status}`
      })
    } else if (childObjectIds.length) {
      this.alertModule.pushAlert({
        color: 'grey darken-1',
        message: `Set ${childObjectIds.length} object${childObjectIds.length === 1 ? '' : 's'} to ${status}`
      })
    }

    this.editorModule.addTaskList({
      revertTasks: [
        ...revertTasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ],
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }
}
