
import { ModelConnection, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { CatalogModule } from '@/modules/catalog/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ModelTechnologyListItemInfo'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  catalogModule = getModule(CatalogModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelConnection
  @Prop() readonly technologyId!: string
  @Prop() readonly permission!: PermissionType

  loading = false
  expanded = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get technology () {
    return this.model.technologies[this.technologyId]
  }

  get catalogTechnology () {
    return this.catalogModule.technologies[this.technology.id]
  }

  get description () {
    return this.catalogTechnology?.description
  }

  get websiteUrl () {
    return this.catalogTechnology?.websiteUrl
  }

  get docsUrl () {
    return this.catalogTechnology?.docsUrl
  }

  get updatesUrl () {
    return this.catalogTechnology?.updatesUrl
  }

  created () {
    this.expanded = false
  }

  async mounted () {
    if (!this.catalogTechnology) {
      try {
        this.loading = true
        await this.catalogModule.catalogTechnologyFind(this.technology.id)
        this.loading = false
      } catch (err: any) {
        this.alertModule.pushAlert({
          color: 'error',
          message: err.message
        })
        this.$emit('error', err.message)
      }
    }
  }

  websiteUrlOpen () {
    if (this.websiteUrl) {
      analytics.modelTechnologyWebsiteLink.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelTechnologyName: this.technology.name,
        modelTechnologyUrl: this.websiteUrl,
        modelType: 'type' in this.model ? this.model.type : 'connection',
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  docsUrlOpen () {
    if (this.docsUrl) {
      analytics.modelTechnologyDocsLink.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelTechnologyName: this.technology.name,
        modelTechnologyUrl: this.docsUrl,
        modelType: 'type' in this.model ? this.model.type : 'connection',
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  updatesUrlOpen () {
    if (this.updatesUrl) {
      analytics.modelTechnologyUpdatesLink.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelTechnologyName: this.technology.name,
        modelTechnologyUrl: this.updatesUrl,
        modelType: 'type' in this.model ? this.model.type : 'connection',
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  readMore () {
    this.expanded = !this.expanded

    analytics.modelTechnologyDescriptionExpand.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelTechnologyName: this.technology.name,
      modelType: 'type' in this.model ? this.model.type : 'connection',
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
