
import { ModelConnection, ModelConnectionPartial, ModelConnectionTechnology, ModelObject, ModelObjectPartial, ModelObjectTechnology, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { CatalogModule } from '@/modules/catalog/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import Item from './item.vue'
import ItemActions from './item-actions.vue'
import ItemInfo from './item-info.vue'

@Component({
  components: {
    Draggable,
    Item,
    ItemActions,
    ItemInfo
  },
  name: 'ModelTechnologyList'
})
export default class extends Vue {
  catalogModule = getModule(CatalogModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly itemRefs!: Item[]

  @Prop() readonly model!: ModelObject | ModelConnection
  @Prop() readonly permission!: PermissionType

  expandedItemId: string | null = null
  optionsMenuItemId: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get technologies () {
    return Object.values<ModelObjectTechnology | ModelConnectionTechnology>(this.model?.technologies || {}).sort(sort.index)
  }

  mounted () {
    this.technologies
      .filter(o => !this.catalogModule.technologies[o.id])
      .forEach(o => this.catalogModule.catalogTechnologyFind(o.id))
  }

  toggleExpandedItems (id: string) {
    if (this.expandedItemId === id) {
      this.expandedItemId = null
    } else {
      this.expandedItemId = id

      const technologyName = this.model.technologies?.[id]?.name
      if (technologyName) {
        analytics.modelTechnologyExpand.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelTechnologyName: technologyName,
          modelType: 'type' in this.model ? this.model.type : 'connection',
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }

  updateLinkIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      const oldTechnologies = [...this.technologies]
      const element = oldTechnologies[moved.oldIndex]
      oldTechnologies.splice(moved.oldIndex, 1)
      oldTechnologies.splice(Math.max(0, moved.newIndex), 0, element)

      const tasks: Task[] = []
      const revertTasks: Task[] = []

      if ('type' in this.model) {
        const technologies = oldTechnologies.reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
          ...p,
          [c.id]: {
            ...c,
            index: i
          }
        }), {})

        if (this.currentDiagram?.status === 'draft') {
          revertTasks.push({
            id: this.currentDiagram.id,
            props: {
              tasksProposed: {
                $append: [{
                  id: this.model.id,
                  props: {
                    technologies: this.model.technologies
                  },
                  type: 'model-object-update'
                }]
              }
            },
            type: 'diagram-content-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })

          const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
            tasksProposed: {
              $append: [{
                id: this.model.id,
                props: {
                  technologies
                },
                type: 'model-object-update'
              }]
            }
          })
          this.diagramModule.setDiagramContentVersion(diagramContent)
          this.editorModule.addToTaskQueue({
            func: () => this.diagramModule.diagramContentUpdate({
              diagramId: diagramContent.id,
              landscapeId: this.currentLandscape.id,
              props: diagramContentUpdate,
              versionId: this.currentVersion.id
            })
          })

          tasks.push({
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })
        } else {
          revertTasks.push({
            id: this.model.id,
            props: {
              technologies: this.model.technologies
            },
            type: 'model-object-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.model.id, {
            technologies
          })
          this.modelModule.setObjectVersion(object)
          this.editorModule.addToTaskQueue({
            func: () => this.modelModule.objectUpdate({
              landscapeId: this.currentLandscape.id,
              objectId: object.id,
              props: objectUpdate,
              versionId: this.currentVersion.id
            })
          })

          tasks.push({
            id: object.id,
            props: objectUpdate,
            type: 'model-object-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })
        }
      } else {
        const technologies = oldTechnologies.reduce<Record<string, ModelConnectionTechnology>>((p, c, i) => ({
          ...p,
          [c.id]: {
            ...c,
            index: i
          }
        }), {})

        if (this.currentDiagram?.status === 'draft') {
          revertTasks.push({
            id: this.currentDiagram.id,
            props: {
              tasksProposed: {
                $append: [{
                  id: this.model.id,
                  props: {
                    technologies: this.model.technologies
                  },
                  type: 'model-connection-update'
                }]
              }
            },
            type: 'diagram-content-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })

          const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
            tasksProposed: {
              $append: [{
                id: this.model.id,
                props: {
                  technologies
                },
                type: 'model-connection-update'
              }]
            }
          })
          this.diagramModule.setDiagramContentVersion(diagramContent)
          this.editorModule.addToTaskQueue({
            func: () => this.diagramModule.diagramContentUpdate({
              diagramId: diagramContent.id,
              landscapeId: this.currentLandscape.id,
              props: diagramContentUpdate,
              versionId: this.currentVersion.id
            })
          })

          tasks.push({
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })
        } else {
          revertTasks.push({
            id: this.model.id,
            props: {
              technologies: this.model.technologies
            },
            type: 'model-connection-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(this.model.id, {
            technologies
          })
          this.modelModule.setConnectionVersion(connection)
          this.editorModule.addToTaskQueue({
            func: () => this.modelModule.connectionUpdate({
              connectionId: connection.id,
              landscapeId: this.currentLandscape.id,
              props: connectionUpdate,
              versionId: this.currentVersion.id
            })
          })

          tasks.push({
            id: connection.id,
            props: connectionUpdate,
            type: 'model-connection-update'
          }, {
            route: this.$route,
            type: 'navigation'
          })
        }
      }

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })
    }
  }

  removeTechnology (technologyId: string) {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if ('type' in this.model) {
      const prevObject = window.structuredClone(this.model)

      const update: Omit<ModelObjectPartial, 'commit'> = {
        technologies: Object
          .values(this.model.technologies)
          .filter(o => o.id !== technologyId)
          .sort(sort.index)
          .reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
            ...p,
            [c.id]: {
              ...c,
              index: i
            }
          }), {})
      }

      if (this.model.icon?.catalogTechnologyId === technologyId) {
        update.icon = null
      }

      if (this.model.name === prevObject.technologies[technologyId]?.name) {
        update.name = ''
        update.external = false
      }

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: this.model.id,
                props: {
                  external: this.model.external,
                  icon: this.model.icon,
                  name: this.model.name,
                  technologies: this.model.technologies
                },
                type: 'model-object-update'
              }]
            }
          },
          type: 'diagram-content-update'
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: this.model.id,
              props: update,
              type: 'model-object-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: this.model.id,
          props: {
            external: this.model.external,
            icon: this.model.icon,
            name: this.model.name,
            technologies: this.model.technologies
          },
          type: 'model-object-update'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.model.id, update)
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: this.model.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        })

        analytics.modelObjectUpdate.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectDescriptionLength: prevObject.description?.length || 0,
          modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
          modelObjectExternal: prevObject.external,
          modelObjectIconName: prevObject.icon?.name || null,
          modelObjectLinkCount: Object.keys(prevObject.links).length,
          modelObjectNameLength: prevObject.name.length,
          modelObjectParent: prevObject.parentId,
          modelObjectStatus: prevObject.status,
          modelObjectTagCount: prevObject.tagIds.length,
          modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
          modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
          modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
          modelObjectType: prevObject.type,
          modelObjectUpdateTechnologyCount: objectUpdate.technologies ? Object.keys(objectUpdate.technologies).length : undefined,
          modelObjectUpdateTechnologyNames: objectUpdate.technologies ? Object.values(objectUpdate.technologies).map(o => o.name) : undefined,
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    } else {
      const prevConnection = window.structuredClone(this.model)

      const update: Omit<ModelConnectionPartial, 'commit'> = {
        technologies: Object
          .values(this.model.technologies)
          .filter(o => o.id !== technologyId)
          .sort(sort.index)
          .reduce<Record<string, ModelConnectionTechnology>>((p, c, i) => ({
            ...p,
            [c.id]: {
              ...c,
              index: i
            }
          }), {})
      }

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: this.model.id,
                props: {
                  name: this.model.name,
                  technologies: this.model.technologies
                },
                type: 'model-connection-update'
              }]
            }
          },
          type: 'diagram-content-update'
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: this.model.id,
              props: update,
              type: 'model-connection-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: this.model.id,
          props: {
            name: this.model.name,
            technologies: this.model.technologies
          },
          type: 'model-connection-update'
        })

        const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(this.model.id, update)
        this.modelModule.setConnectionVersion(connection)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.connectionUpdate({
            connectionId: this.model.id,
            landscapeId: this.currentLandscape.id,
            props: connectionUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: connection.id,
          props: connectionUpdate,
          type: 'model-connection-update'
        })

        analytics.modelConnectionUpdate.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelConnectionDescriptionLength: prevConnection.description?.length || 0,
          modelConnectionDirection: prevConnection.direction,
          modelConnectionNameLength: prevConnection.name.length,
          modelConnectionStatus: prevConnection.status,
          modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
          modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
          modelConnectionUpdateTechnologyCount: connectionUpdate.technologies ? Object.keys(connectionUpdate.technologies).length : undefined,
          modelConnectionUpdateTechnologyNames: connectionUpdate.technologies ? Object.values(connectionUpdate.technologies).map(o => o.name) : undefined,
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })
  }
}
