import { ModelConnection, ModelObject } from '@icepanel/platform-api-client'

export const directConnections = (originId: string, targetId: string, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).filter(o => {
    const directOutgoingConnection = o.direction === 'outgoing' && o.originId === originId && o.targetId === targetId
    const directBidirectionalConnection = (!o.direction || o.direction === 'bidirectional') && ((o.originId === originId && o.targetId === targetId) || (o.originId === targetId && o.targetId === originId))
    return directOutgoingConnection || directBidirectionalConnection
  })
}

export const lowerConnections = (originObject: ModelObject, targetObject: ModelObject, allObjects: Record<string, ModelObject>, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).filter(o => {
    const originFamilyIds = [o.originId, ...allObjects[o.originId]?.parentIds ?? []]
    const targetFamilyIds = [o.targetId, ...allObjects[o.targetId]?.parentIds ?? []]

    const directOutgoingConnection = o.direction === 'outgoing' && o.originId === originObject.id && o.targetId === targetObject.id
    const directBidirectionalConnection = (!o.direction || o.direction === 'bidirectional') && ((o.originId === originObject.id && o.targetId === targetObject.id) || (o.originId === targetObject.id && o.targetId === originObject.id))

    const lowerOutgoingConnection = o.direction === 'outgoing' && originObject.id && targetObject.id && originFamilyIds.includes(originObject.id) && targetFamilyIds.includes(targetObject.id)
    const lowerBidirectionalConnection = (!o.direction || o.direction === 'bidirectional') && ((originObject.id && targetObject.id && originFamilyIds.includes(originObject.id) && targetFamilyIds.includes(targetObject.id)) || (originObject.id && targetObject.id && originFamilyIds.includes(targetObject.id) && targetFamilyIds.includes(originObject.id)))

    return !directOutgoingConnection && !directBidirectionalConnection && (lowerOutgoingConnection || lowerBidirectionalConnection)
  })
}

export const directOutgoingConnections = (objectId: string, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).reduce((p, c) => {
    if (c.originId === objectId) {
      const key = `${c.originId}-${c.targetId}`
      if (!p[key]) {
        p[key] = []
      }
      p[key].push(c.id)
    } else if ((!c.direction || c.direction === 'bidirectional') && c.targetId === objectId) {
      const key = `${c.targetId}-${c.originId}`
      if (!p[key]) {
        p[key] = []
      }
      p[key].push(c.id)
    }
    return p
  }, {} as Record<string, string[]>)
}

export const directIncomingConnections = (objectId: string, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).reduce((p, c) => {
    if (c.targetId === objectId) {
      const key = `${c.originId}-${c.targetId}`
      if (!p[key]) {
        p[key] = []
      }
      p[key].push(c.id)
    } else if ((!c.direction || c.direction === 'bidirectional') && c.originId === objectId) {
      const key = `${c.targetId}-${c.originId}`
      if (!p[key]) {
        p[key] = []
      }
      p[key].push(c.id)
    }

    return p
  }, {} as Record<string, string[]>)
}

export const lowerOutgoingConnections = (object: ModelObject, objectFamilyIds: Record<string, string[]>, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).reduce((p, c) => {
    const originFamilyIds = objectFamilyIds[c.originId] ?? []
    const targetFamilyIds = objectFamilyIds[c.targetId] ?? []

    originFamilyIds.forEach(originId => {
      targetFamilyIds.forEach(targetId => {
        if (
          (targetId === object.id || originId === object.id) &&
          (targetId !== c.targetId || originId !== c.originId) &&
          !object.parentIds.includes(originId) &&
          !object.parentIds.includes(targetId)
        ) {
          if (originFamilyIds.includes(object.id) && !targetFamilyIds.includes(object.id)) {
            const key = `${originId}-${targetId}`
            if (!p[key]) {
              p[key] = []
            }
            p[key].push(c.id)
          } else if ((!c.direction || c.direction === 'bidirectional') && targetFamilyIds.includes(object.id) && !originFamilyIds.includes(object.id)) {
            const key = `${targetId}-${originId}`
            if (!p[key]) {
              p[key] = []
            }
            p[key].push(c.id)
          }
        }
      })
    })

    return p
  }, {} as Record<string, string[]>)
}

export const lowerIncomingConnections = (object: ModelObject, objectFamilyIds: Record<string, string[]>, allConnections: Record<string, ModelConnection>) => {
  return Object.values(allConnections).reduce((p, c) => {
    const originFamilyIds = objectFamilyIds[c.originId] ?? []
    const targetFamilyIds = objectFamilyIds[c.targetId] ?? []

    originFamilyIds.forEach(originId => {
      targetFamilyIds.forEach(targetId => {
        if (
          (targetId === object.id || originId === object.id) &&
          (targetId !== c.targetId || originId !== c.originId) &&
          !object.parentIds.includes(originId) &&
          !object.parentIds.includes(targetId)
        ) {
          if (targetFamilyIds.includes(object.id) && !originFamilyIds.includes(object.id)) {
            const key = `${originId}-${targetId}`
            if (!p[key]) {
              p[key] = []
            }
            p[key].push(c.id)
          } else if ((!c.direction || c.direction === 'bidirectional') && originFamilyIds.includes(object.id) && !targetFamilyIds.includes(object.id)) {
            const key = `${targetId}-${originId}`
            if (!p[key]) {
              p[key] = []
            }
            p[key].push(c.id)
          }
        }
      })
    })

    return p
  }, {} as Record<string, string[]>)
}
