import { ModelStatusType } from '@icepanel/platform-api-client'

const icons: Record<ModelStatusType, string> = {
  deprecated: '$fas-ban',
  future: '$fas-spinner',
  live: '$custom-duotone-dot-circle',
  removed: '$fas-times'
}

export default icons
